<template>
  <div class="container pd100 schedule-calendar">
      <van-sticky>
        <div class="schedule-header van-hairline--bottom">
          <div @click.stop="showPicker = true" class="schedule-header-hd">{{columns[pickerIndex]}}</div>
          <div class="schedule-header-bd">{{active}}</div>
          <div class="schedule-header-ft"></div>
        </div>
      </van-sticky>

      <vue-hash-calendar 
        ref="calendar"
        weekStart='monday'
        @click="handleClick"
        @change="handleChange"
        @slidechange="handleSlidechange"
        checkedDayClassName="schedule-calendar-color"
        format="YY-MM-DD"
        :visible="true" 
        :isShowArrow="false" 
        :isShowAction="false" 
        :isShowWeekView="isShowWeekView"
        :scrollChangeDate="true"
        :markDate="markDate">

        <!-- <div @click="isShowWeeks" slot="arrow">
          <img v-if="isShowWeekViews" src="@/assets/download.png">
          <img v-else src="@/assets/download-2.png">
        </div> -->
      </vue-hash-calendar>

      <div v-if="list.length > 0" class="schedule-list">
        <div v-for="course in list" :key="course.course_class_id" class="schedule-list-item" @click="onNavigator({path:'/teacher/checkin?course_class_id='+course.course_class_id})">
          <div class="schedule-list-item-hd">
            <div class="schedule-list-item-dot" />
            <div class="schedule-list-item-time">
              <div class="schedule-list-item-time-title"> {{ course.class_time.substr(0,5) }}</div>
              <div class="schedule-list-item-time-txt disabled">{{ dayjs(course.class_date).format('MM-DD') }}</div>
              <div class="schedule-list-item-time-txt disabled">{{ dayjs(course.class_date).format('dddd') }}</div>

              <!-- <div v-if="course.book_status === 1" class="schedule-list-item-time-txt">待上课</div> -->
              <div class="schedule-list-item-time-txt orange">{{course.signin_count}}/{{course.reserve_count}}/{{course.table_count}}</div>


              <!-- <div class="schedule-list-item-time-txt orange">{{course.signin_count}}/{{course.reserve_count}}/{{course.table_count}}</div> -->

            </div>
          </div>
          <div class="schedule-list-item-bd">
            <div class="schedule-list-item-title van-ellipsis">
              {{ course.course_name }}
            </div>
            <!-- <div v-if="course.course_teacher" class="schedule-list-item-teacher">              
              <span>老师：<van-image class="schedule-list-item-teacher-img" :src="course.course_teacher.teacher_avatar" />{{ course.course_teacher.teacher_name }}</span>
            </div> -->
            <div class="schedule-list-item-location">上课老师：{{ course.teacher}} {{course.class_time}}</div>
            <div class="schedule-list-item-location">上课教室：{{ course.room_name }}</div>
            <div class="schedule-list-item-location">上课校区：{{ course.course_studio && course.course_studio.studio_name }} </div>            
          </div>

        </div>
      </div>

      <!-- <div v-if="list.length > 0 && 0" class="list">
        <div v-for="item in list" :key="item.course_class_id" class="list-item van-hairline--bottom" @click="onNavigator({path:'/teacher/checkin?course_class_id='+item.course_class_id})">
            <div class="list-item-h2">{{ item.course_name }}</div>
            <div class="list-item-title">校区：{{ item.course_studio.studio_name }}</div>
            <div class="list-item-title">班级：{{ item.classes_name }}</div>
            <div class="list-item-title">教室：{{ item.room_name }}</div>
            <div class="list-item-title">老师：{{ item.teacher }}</div>
            <div class="list-item-title">学员：{{ item.reserve_count }}人</div>
            <div class="list-item-title">时间：{{ item.class_date }} {{ item.class_time }}</div>
            <div class="list-item-arrow">详情<van-icon name="arrow" /></div>
        </div>
      </div> -->

    <div v-else class="no-data">
      <van-empty description="暂无相关数据~" />
    </div>

    <copyright />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'
import Schedule from '@/components/Schedule'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
import no_order from '@/assets/no_order.png'
import { Sticky, Popup, Picker,Empty } from 'vant'
import Copyright from '@/components/Copyright'
import Avatar from 'vue-avatar'

export default {
  name: 'TeacherSchedule',
  components: {
    Copyright,
    [Empty.name]: Empty,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Sticky.name]: Sticky,
    schedule: Schedule,
    [Avatar.name]: Avatar,
    loadings:Loadings,
  },
  filters: {
    formatEnd(value) {
      return value && value.slice(-8)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      active: dayjs().format('YYYY-MM-DD'),
      weeks: [],
      no_order,
      showPicker: false,
      searchLoading:false,
      isShowWeekView:true,
      isShowWeekViews:true,
      list: [],
      markDate:[],
      pickerIndex:0,
      columns: ['我的','我下属的'],
      start_date:dayjs(dayjs().startOf('month')).add(-1, 'month').format('YYYY-MM-DD'),
      end_date:dayjs(dayjs().endOf('month')).add(1, 'month').format('YYYY-MM-DD'),
    }
  },
  created() {
    if(localStorage.getItem("is_sub")){
        this.pickerIndex = localStorage.getItem("is_sub")
    }
    this.getList()
  },
  activated() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
      if (to.path === '/teacher/checkin') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
          this.$store.commit('app/updateCatchComponents', ['TeacherSchedule'])
      } else {
          this.$store.commit('app/updateCatchComponents', [])
      }
      next()
  },
  methods: {
    dayjs,
    initWeeks(item) {
      return {
        id: '',
        date: item.date,
        txt: item.txt,
        txtDate: item.txt_date,
        dot: item.courses && item.courses.length
      }
    },
    onList() {
      this.searchLoading = true
      this.$api.employee_schedule({ start_date:this.start_date, end_date:this.end_date}).then(res => {
        this.weeks = res.data.schdule_list.map(item => this.initWeeks(item))
        const current = res.data.schdule_list.find(item => item.date === this.active) || {}
        this.list = current.courses
        this.searchLoading = false
      })
    },
    onScheduleChange(value) {
      this.onList()
    },
    getList(){
      this.searchLoading = true
      this.$api.employee_schedule({ start_date:this.start_date, end_date:this.end_date,is_sub:this.pickerIndex}).then(res => {
        let markDate = []
        this.weeks = res.data.schdule_list
        res.data.schdule_list.forEach(item=>{
          markDate.push(dayjs(item.date).format('YYYY/MM/DD'))
          if(item.date == this.active){
            this.list = item.courses
          }
        })
        this.markDate = [{color: '#ecbe4b',date: markDate}]
        this.searchLoading = false
      })
    },
    handleClick(e){
      for(let i=0; i<this.weeks.length;i++){
        if(this.weeks[i].date == e){
          this.list = this.weeks[i].courses
          break
        }else{
          this.list = []
        }
      }
    },
    handleChange(e){
      this.active = e
      this.start_date = dayjs(dayjs(e).startOf('month')).add(-1, 'month').format('YYYY-MM-DD')
      this.end_date = dayjs(dayjs(e).endOf('month')).add(1, 'month').format('YYYY-MM-DD')
    },
    handleSlidechange(e){
      if (e=='up' || e=='down') return false
      let that = this
      setTimeout(()=>{
        that.getList()
      },0)
    },
    isShowWeeks(){
      this.isShowWeekViews = !this.isShowWeekViews
      localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
    },
    onConfirm(val,index){
      localStorage.setItem('is_sub', index)
      if(this.pickerIndex != index){
        this.pickerIndex = index
        this.list=[];
        this.getList()
      }
      this.showPicker = false
    }
  }
}
</script>
<style lang="less" scoped>
  .schedule-list{
    // min-height: 220px;
    padding: 10px 15px;
  }

  @height:80px;
  .schedule-list-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    background: #fff;
    padding-left: 10px;
    position: relative;
    &-hd{
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 10px;
      height: @height;
      padding-left: 20px;
      padding-top: 8px;
    }
    &-dot{
      position: absolute;
      top: 14px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      overflow: hidden;
      background: #00DE93;
      margin-right: 20px;
    }
    &-time{
      font-weight: bold;
      font-size: 16px;
      &-title{
        // margin-bottom: 5px;
      }
      &-txt{
        font-size: 12px;
        font-weight: normal;
      }
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
      font-weight: bold;
    }
    &-teacher{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 10px;
      color: #666;
      margin-bottom: 5px;
      &-img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
      }
    }
    &-bd{
      box-sizing: border-box;
      // height: @height;
      padding: 10px 15px;
      // background: #f6f9fc;
      border-radius: 4px;
      flex: 1;
      font-size: 10px;
      color: #666;
      min-width: 0;
    }
    .gray{
      color: #b2b2b2;
    }
    .orange{
      color: #ff993e;
    }
    &-chat{
      position: absolute;
      top: 30px;
      right: 10px;
    }
  }

  .schedule{
    &-header{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      padding:0 15px;
      background: #fff;
      &-hd{
        width: 60px;
      }
      &-ft{
        width: 60px;
      }
      &-bd{
        flex: 1;
        height: 44px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  
  .list{
    background: #fff;
    min-height: 220px;
  }
  .list-item{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 220px;
    padding: 20px 15px;
    &-h2{
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-title{
      color: #666;
      font-size: 14px;
      margin-bottom: 6px;
    }
    &-arrow{
      position: absolute;
      top: 19px;
      right: 15px;
      color: #999;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-self: start;
    }
    &-action{
      position: absolute;
      bottom: 19px;
      right: 15px;
    }
  }

  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>
<style lang="less">
  .schedule-calendar{
    .calendar_dot{
      width: 4PX;
      height: 4PX;
    }
    .calendar_content{
      overflow: initial;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
    }
    .calendar_first_today{
      color:#00cca2;
    }
  }
</style>

